














































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
  import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";
  import QuestDetailsResponsive from "@/views/QuestDetailsResponsive.vue";
  import CardDetails from "@/components/CardDetails.vue";
  import Card from "@/components/Card.vue";
  import NewCardResponsive from "@/views/NewCardResponsive.vue";

  @Component({
  name: 'DashboardResponsive',
    components: {NewCardResponsive, QuestDetailsResponsive, CardDetails, Card}
  })
  export default class DashboardResponsive extends Vue {
  protected sidebarOpen = false;
  protected notificationOpen = false;
  protected dropdownOpen = false;
  protected database: IndexDBClient;

  protected isOpen = false;
  private details = "#Loading Card Details";

  protected isNewCard = false;
  protected chooseBoard = false;

  private numAllCards = 0;
  private numCardsInProgress = 0;
  private numCardsInReview = 0;
  private numCardsOnHold = 0;
  private numCardsCompleted = 0;
  private boardName = {id:'', name:"Bear vs Goats"};

  protected cards = [];
  protected card: any;
  protected boardList = [];

  protected nameOfNewBoard = "";
  protected boardIdSelected = "";

  constructor() {
    super();
    this.database = new IndexDBClient();
    this.isOpen = false;
    this.card = {status:{icon:'exclamation-circle', name:'Not Assigned'}};
    this.boardIdSelected = "1";

    this.$emit('fabEvent');
  }

    openCloseMenu() {
      this.isOpen = !this.isOpen;
    }

    showCardDetails( card: any ){
     this.isOpen = true;
     this.details = card.cardId;
     this.card = card;
    }

    get cardDetails(): string {
      const d = this.details;
      return d;
    }

    get getCard(): any {
      const c = this.card;
      return c;
    }

    get assignedCards() {
      this.database.getAssignedCards(this.selectedBoard.id).then((cards: any) => {
          this.cards = cards;
      })
      return this.cards;
    }

    showCard(card: any): boolean {
    const searchingText: any = this.$store.state.searching;
      if(searchingText !== "") {
        if(card.title.toLowerCase().indexOf(searchingText.toLowerCase()) != -1) {
          if(!this.$route.query.filter || this.$route.query.filter == "-1")
            return true;
          return card.status.id == this.$route.query.filter;
        }
        else{
          return false;
        }
      }
      else {
        if(!this.$route.query.filter || this.$route.query.filter == "-1")
          return true;
        return card.status.id == this.$route.query.filter;
      }
    }

    get allCards(){
      this.database.getAssignedCards(this.selectedBoard.id).then((cards: any) => {
        this.numAllCards = cards.length;
      })
      return this.numAllCards;
    }

    get cardsInProgress(){
      this.database.cardsInProgress(this.selectedBoard.id).then( (cards: any) => {
        this.numCardsInProgress = cards.length;
      })
      return this.numCardsInProgress;
    }

    get cardsInReview(){
      this.database.cardsInReview(this.selectedBoard.id).then( (cards: any) => {
        this.numCardsInReview = cards.length;
      })
      return this.numCardsInReview;
    }

    get cardsOnHold() {
      this.database.cardsOnHold(this.selectedBoard.id).then( (cards: any) => {
        this.numCardsOnHold = cards.length;
      })
      return this.numCardsOnHold;
    }

    get cardsCompleted() {
      this.database.cardsCompleted(this.selectedBoard.id).then( (cards: any) => {
        this.numCardsCompleted = cards.length;
      })
      return this.numCardsCompleted;
    }

    get selectedBoard() {
        this.database.selectedBoard.then( (board: any) => {
          this.boardName = board[0];
          this.boardIdSelected = board[0].id;
        })
      return this.boardName;
    }

    addNewCard(){
      this.isNewCard = true;
    }

    openCloseNewCard() {
      this.isNewCard = !this.isNewCard
    }

    get boards() {
      this.database.boards.then( (board: any) => {
        this.boardList = board;
      })
      return this.boardList
    }

    openCloseBoard() {
      this.chooseBoard = !this.chooseBoard;
    }

    showBoardOptions(){
      this.chooseBoard = false;
    }

    selectBoard(id) {
      this.database.updateSelectedBoard(id);
      this.boardName = this.selectedBoard;
      this.boardIdSelected = id;
      this.$store.state.selectedBoard = this.selectedBoard;
    }

    async createNewBoard() {
      const boardList = await this.database.boards;

      this.database.boards = {
        id: ""+(boardList.length + 1),
        statusId: 1,
        name: this.nameOfNewBoard,
        isSelected: false
      }

      this.nameOfNewBoard = "";
    }

    deleteBoard(boardId) {
      this.boardList = this.boardList.filter((board: any) => boardId !== board.id);
      this.database.deleteBoard(boardId);
    }
}

